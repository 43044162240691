
.hide-sm {
  display: block;
}

.hide-md {
  display: none;
}

//small screen
@media screen and (max-width: 768px) {
  .hide-sm {
    display: none;
  }

  .hide-md {
    display: block;
  }
}